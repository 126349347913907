import React, { useState, useEffect } from "react"
// import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie"
import styled from "styled-components"

import GlobalFonts from "../font/fonts"

import Popup from "react-popup"

import Header from "../components/header"
import ResponsiveAppBar from "../components/ResponsiveAppBar"
import NavV2 from "../components/NavV2"
import NextButton from "../components/NextButton"
// 
import TestiCards from "../components/testicards"
import FAQ from "../components/FAQ"
import FooterContainer from "../components/FooterContainer"
import Signup from "../components/Signup"
import ExplanationVideo from "../components/ExplanationVideo"
import Stepbystep from "../components/Stepbystep"

import gray_logo_text from "../images/cloudCastleLogo_dark_gray.png"
import main_landing_page_qr from "../images/main_landing_page_qr.png"
import EbookCloudCastlesCover from "../images/EbookCloudCastlesCover.png" 

import topbackground from "../assets/TopPolygon1.svg"
import topbackground2 from "../assets/TopPolygon2.svg"

import phones from "../assets/Phones2.svg"
import shadowPhones from "../assets/shadowPhones.svg"
import white_logo_text from "../images/white_logo_text.png"

import path1 from "../assets/Arrow1.svg"
import path2 from "../assets/Arrow2.svg"
import path3 from "../assets/Arrow3.svg"

import icon1 from "../assets/ICON.svg"
import icon2 from "../assets/ICON2.svg"
import icon3 from "../assets/ICON3.svg"
import icon4 from "../assets/ICON4.svg"

import vectorBackground from "../assets/VectorBackground.svg"
import backgroundSlide from "../assets/BackgroundSlide.svg"
import wave from "../assets/Wave.svg"
import waveTop from "../assets/WaveTop.svg"

import Fab from "@mui/material/Fab"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import "bootstrap/dist/css/bootstrap.min.css"

import Helmet from "react-helmet"

const App2 = () => {
  const [email, setEmail] = React.useState("")
  const [emailError, setEmailError] = React.useState("")
  const [emailSent, setEmailSent] = React.useState("")

  const toForm = () => {
    window.location.href = "/own-a-vacation-home"

  }

  const handleSumit = event => {
    console.log("FUCK YOU")
    // use to make sure page isn't reloaded when we send off content
    event.preventDefault()
    // don't remember from where i copied this code, but this works.
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/


    if (re.test(email)) {
      // this is a valid email address
      // call setState({email: email}) to update the email
      // or update the data in redux store.

      var axios = require("axios")
      var data = JSON.stringify({
        email: email,
        dataFields: { source: "home_web" },
      })

      // frontend iterable
      var config = {
        method: "post",
        url: "https://api.iterable.com/api/users/update",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "dd373517425447ca8ab5d993282d17ab",
        },
        data: data,
      }

      axios(config)
        .then(function (response) { 
          console.log(JSON.stringify(response.data))
          setEmailSent("We'll send you info!")
          toForm()
        })
        .catch(function (error) {
          console.log(error)

        })

      console.log(email)
      setEmailError("")
    } else {
      // invalid email, maybe show an error to the user.
      console.log(email)
      setEmailSent("")
      setEmailError("Please set a valid email")

    }
  }

  // let navigate = useNavigate();
  const routeChange = () => {
    let path = `https://cloudcastles.onelink.me/GiaO?pid=HomePage&c=Fab&is_retargeting=true&af_android_url=https://play.google.com/store/apps/details?id%3Dcom.cloudcastles.bmarks`
    console.log(path)
    window.location.href = path
    // navigate(path);
  }

  

  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
    console.log(position)
    
    if (position > 3040) {
      console.log("ya boi")
      
          
      var cc_pop = localStorage.getItem("cc_pop");
      console.log(cc_pop)
      if (cc_pop != "true") {
        setShow(true)  
      } else {
        
      }

      localStorage.setItem("cc_pop", "true");  

        
      
    }
  }

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Cloud Castles Vacation Homes</title>
        <meta
          name="google-site-verification"
          content="lRDxE-lZUVoBRi5yV2AHoVkcaALQME9xGG-XwQYIbcU"
        />{" "}
        <meta
          name="keywords"
          content="Vacation home Real estate investing, is real estate investing worth it, is real estate investing a business,how to start real estate investing"
        />
        <meta
          name="description"
          content="Allowing Anyone To Own Anywhere. Your go to Vacation home Real estate investing company."
        />
      <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-207899923-1"
        ></script>
        <script type="application/ld+json">{`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('config', 'UA-207899923-1');
  `}</script>
      </Helmet>
      <ResponsiveAppBar />
      <Body>
        <GlobalFonts />
        {/*<Fab style={style} onClick={routeChange}>
          <Logo2 src={gray_logo_text}></Logo2>
          <Logo3 src={main_landing_page_qr}></Logo3>
          <PText2>Download Now</PText2>
        </Fab>*/}
        <Tbackground>
          <TopBackground src={topbackground} />
          <TopBackground1 src={topbackground} />
          <TopBackground2 src={topbackground2} />
        </Tbackground>
        <Hero>
          <Hero1>
            <Phones src={phones} />
            <Shadowphone src={shadowPhones} />
          </Hero1>

          <Hero2>
            {/* <Logo src={white_logo_text}  */}
            {/* ></Logo> */}
            <H1Text>Thanks!</H1Text>
            <PText>
              We'll send you details on how to get started soon.
            </PText>
{/*            <SignupDiv>*/}
              {/*<Signup />*/}
            {/*</SignupDiv>*/}
            {/*<CTADiv>
              <CTAForm
                style={{
                  flexDirection: "row",
                  display: "grid",
                  jusityitems: "center",
                  alignitems: "center",
                }}
                onSubmit={toForm}
              >
                <CTAInput
                  type="text"
                  placeholder="email"
                  error={emailError}
                  onChange={event => setEmail(event.target.value)}
                ></CTAInput>

                <CTAButton onClick={handleSumit}type="submit">Learn More</CTAButton>
              </CTAForm>
              <span style={{ color: "red" }}>{emailError}</span>
              <span style={{ color: "black" }}>{emailSent}</span>
            </CTADiv>*/}
          </Hero2>
        </Hero>
          <FooterContainer />
        
      </Body>
    </>
  )
}

export default App2

const style = {
  margin: 0,
  top: "auto",
  left: 20,
  bottom: 20,
  height: 150,
  width: 150,
  zIndex: 100,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  left: "auto",
  position: "fixed",
}

const Body = styled.div`
  position: relative;
`

const Tbackground = styled.div`
  max-width: 100%;
  overflow-x: hidden;
`

const Hero = styled.div`
  max-width: 100%;
  overflow-x: hidden;

  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  margin: 10px auto;

  @media only screen and (max-width: 625px) and (min-width: 100px) {
    flex-direction: column-reverse;
    margin: -70px 50px 50px -20px;
  }
`

const Logo = styled.img`
  max-width: 40%;

  /*padding: 0px 0px 0px 30px;*/

  margin-right: 30px;
  position: relative;
  @media only screen and (max-width: 40em) {
  }
`

const Logo2 = styled.img`
  /*max-width: 40%;*/

  /*padding: 0px 0px 0px 30px;*/
  height: 30px;
  /*margin: 4px auto;*/
  /*margin-right: 30px;*/
  position: relative;
  @media only screen and (max-width: 40em) {
  }
`

const Logo3 = styled.img`
  /*max-width: 40%;*/
  height: 50px;
  /*padding: 0px 0px 0px 30px;*/

  margin: 4px auto;
  position: relative;
  @media only screen and (max-width: 40em) {
  }
`

const Hero1 = styled.div`
  
  display: flex;
margin: 100px auto 0px 0px;
padding 100px auto auto 100px;
  justify-content: center;
  align-content: center;
  margin: auto;

  @media only screen and (max-width: 625px) and (min-width: 100px) {

    margin: 100px auto 50px 100px;

  }  

  
`

const Hero2 = styled.div`
  
  
  margin:  auto;
  padding 100px 0px 0px 100px;
    flex: 1;
  align-items:space-evenly;
  justify-content: center;
`

const CTADiv = styled.div``

const TopBackground = styled.img`
  position: absolute;
  left: -14.63%;
  right: 9.75%;
  top: -4.95%;
  bottom: 78.14%;
  z-index: -10;

  background: #8bbef3;
  border-radius: 333px;
  transform: matrix(0.98, -0.24, 0.2, 0.97, 0, 0);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    width: 1712.46px;
    height: 1556.42px;
    left: -717.75px;
    top: -656.2px;

    background: #8bbef3;
    border-radius: 333px;
    transform: matrix(0.98, -0.24, 0.2, 0.97, 0, 0);
  }
`

const TopBackground1 = styled.img`
  position: absolute;
  left: -14.63%;
  right: 9.75%;
  top: -40.95%;
  bottom: 78.14%;

  background: #8bbef3;
  border-radius: 333px;
  transform: matrix(0.98, -0.24, 0.2, 0.97, 0, 0);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const TopBackground0 = styled.img`
  overflow-x: hidden;
  position: absolute;
  left: -14.63%;
  right: 0.75%;
  top: -0.95%;
  bottom: 8.14%;

  opacity: 0.1;

  background: #8bbef3;
  border-radius: 333px;
  transform: matrix(0.9, -0.24, 0.2, 0.97, 0, 0);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const TopBackground2 = styled.img`
  overflow-x: hidden;
  position: absolute;
  left: -1.72%;
  right: 32.8%;
  top: -16.64%;
  bottom: 81.18%;

  background: #8bbef3;
  opacity: 0.05;
  border-radius: 333px;
  transform: matrix(1, -0.1, 0.08, 1, 0, 0);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`
const Phones = styled.img`
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  margin-left: auto;
  margin-right: 40px;
  margin-top: auto;
  margin-bottom: auto;
  max-width: 80%;

  @media only screen and (max-width: 1280px) and (min-width: 900px) {
    /*margin: 10px 10px 100px 100px;*/
  margin-left: 280px;
  margin-right: 0px;
  // margin-top: auto;
  // margin-bottom: auto;

  }
`
const Shadowphone = styled.img`
  position: absolute;
  width: 829.14px;
  height: 366.75px;
  right: 100.75px;
  top: 400.63px;

  margin: 150px auto 0px auto;
  @media only screen and (max-width: 625px) and (min-width: 100px) {
    margin: 200px -300px 0px 100px;
  }
`

const Testi = styled.h2`
  position: relative;
  width: 551px;
  height: 55px;
  /*left: 320.32px;*/
  top: 0.21px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 36.9714px;
  line-height: 55px;
  text-transform: uppercase;

  color: #000000;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    left: 70.32px;
  }
`

const H1Text = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-size: 35.8802px;
  line-height: 119%;
  /* or 43px */

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  font-feature-settings: "liga" off;

  margin: 30px auto;
  padding: auto;

  color: #ffffff;
  @media only screen and (max-width: 600px) and (min-width: 100px) {
  }
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const PText = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16.7436px;
  line-height: 151%;
  /* or 25px */
  margin: 30px auto;
  color: #fafdff;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const PText2 = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 10.7436px;
  line-height: 151%;
  /* or 25px */
  /*margin: 30px auto;*/
  color: #1d293f;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const CTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  padding-left: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16.1461px;
  line-height: 24px;

  color: #ffffff;

  background: #c73967;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const CTAForm = styled.form`
  margin: auto;
  flex-direction: row;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
`

const CTAInput = styled.input`
  display: flex;
  padding: 16.1461px 30.7772px;

  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16.1461px;
  line-height: 24px;

  color: black;

  border: 0.897006px solid #8578fc;
  box-sizing: border-box;
  filter: drop-shadow(0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26));
  border-radius: 40.3653px;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

/*
const Wave = styled.img`
  position: absolute;
  width: 100%;
  z-index: 0;
    top: 3139.21px;
`

const WaveTop = styled.img`
  position: absolute;
  width: 100%;
  opacity:80%;
  z-index: 0;
    top: 3139.21px;
`
*/

const FooterDiv = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  left: 0px;
  top: 0.21px;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const SignupDiv = styled.div`
  position: relative;

  width: 100%;
  height: 300px;
  z-index: 2;
  top: 55.21px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

class Prompt extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: this.props.defaultValue,
    }

    this.onChange = e => this._onChange(e)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value) {
      this.props.onChange(this.state.value)
    }
  }

  _onChange(e) {
    let value = e.target.value

    this.setState({ value: value })
  }

  render() {
    return (
      <input
        type="text"
        placeholder={this.props.placeholder}
        className="mm-popup__input"
        value={this.state.value}
        onChange={this.onChange}
      />
    )
  }
}
